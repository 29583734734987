import * as React from 'react';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Subheader} from "@shared/ui/headers/sub";
import {BaseText} from "@shared/ui/text/base";
import {Button} from "@shared/ui/buttons/Button";
import {useGetIconsOptimized} from "@shared/hooks/useGetIconsOptimized";
import {useTranslation} from "react-i18next";

// styles
const pageStyles = {
  padding: '96px',
};

// markup
const NotFoundPage = () => {
  const {t} = useTranslation();
  const icon = useGetIconsOptimized().find((imageItem: any) => imageItem.name === 'help-online-block-216px');

  return (
    <main style={pageStyles}>
      <div className="basis-full md:basis-1/2 xl:basis-1/3 md:px-4">
        <div className="help-block-head relative flex flex-col gap-5 items-center pt-4.875 px-6 pb-6 rounded-lg">
          <GatsbyImage image={getImage(icon.content)} alt="" className={'w-[113px] h-[113px]'}/>
          <Subheader className="md:text-2xl lg:text-3xl">
            {t('not-found-header')}
          </Subheader>
          <BaseText align="center" weight="light" className="lg:text-base">
            {t('not-found-message')}
          </BaseText>
          <Button as="link-in" size="small" to="/">
            {t('to-main-page')}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
